import {RouteObject} from "react-router-dom";
import MinimalLayout from "../layout/MinimalLayout";
import SamplePage from "../views/SamplePage";
import ProtectedRoute from "./ProtectedRoute";


const LandingRoute: RouteObject = {
    path: '/',
    element: <ProtectedRoute />,
    children: [
        {
            path: '/',
            element: <MinimalLayout /> ,
            children: [
                { path: '/', element: <SamplePage /> }
            ]
        }
    ]
};

export default LandingRoute;