import { useParams } from "react-router-dom";
import { useState } from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {TMessageDTO} from "../util/types";
import { useJitsiLoader, useMessageQuery, useUserFetch } from "../util/hooks";
import config from "../config/config";
import { Box, Grid, Link } from "@mui/material";
import BottomPanel from "../components/BottomPanel";
import ModeratorFreeTextEditor from "../components/ModeratorFreeTextEditor";
import ModeratorPredefinedTextEditor from "../components/ModeratorPredefinedTextEditor";
import MessageItem from "../components/MessageItem";
import "@fontsource/open-sans/400.css";
import FarewellMessage from "../components/FarewellMessage";
import Logo from "../components/Logo";
import CourtLogo from "../components/CourtLogo";
import {getJwt, getUser} from "../util/oidc";


const NewJitsi = () => {
    const { location, room } = useParams();
    const [node, setNode] = useState<any>(null);
    const [forcedText, setForcedText] = useState<string>('');
    const {messages, isError: isMessageError} = useMessageQuery(`${location}_${room}`);
    const queryClient = useQueryClient();
    const [end, setEnd] = useState<boolean>(false);


    // AUTH DATA
    const {user, isLoading: userLoading} = useUserFetch();
    const {data: jwt, isLoading: jwtLoading} = useQuery(
        ['jwt'],
        () => getJwt(),
        {
            enabled: !!user,
            staleTime: Infinity,
            cacheTime: Infinity,
            refetchOnMount: false,
            retryOnMount: false
        }
    );

    const statuses = [];
    if (isMessageError) {
        statuses.push('Message service unavailable');
    }

    const role = !!jwt ? 'host' : 'guest';

    if (config.debugEnableStatusMessages) {
        statuses.push(`location: ${ location } `);
        statuses.push(`room: ${ room } `);
        statuses.push(`preferred_username: ${ user?.preferredUsername } `);
        statuses.push(`email: ${ user?.email } `);
        statuses.push(`role: ${role} `);
        statuses.push(`jwt: ${ jwt ? jwt : 'NO' } `);
    }

    const enabled = ():boolean => {
        if (jwtLoading || userLoading) return false;
        if (user && jwt) return true;
        if (!user && !jwt) return true;
        return false;
    }

    const { isLoading, isError  } = useJitsiLoader(config.jitsiFQDN, {
        enabled: enabled(),
        role: role,
        preferredName: user?.preferredUsername || "",
        room: room || '',
        location: location || '',
        node: node,
        jwt,
        email: user?.email || '',
        onEnd: () => {
            console.log('JITSI END CALLED');
            setEnd(true)
        }
    });

    const handleChange = (text: string) : void => {
        setForcedText(text);
    }

    const {mutate} = useMutation({
        mutationFn: (payload: {location: string, jwt:string, id: number}) => {
            const url = `${config.backendURL}/api/messages/${encodeURIComponent(payload.location)}/${payload.id}`;
            const options: RequestInit = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            };
            return fetch(url, options);
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(['messages', `${location}_${room}`]);
        }
    });

    const handleDelete = (message: TMessageDTO) : void => {
        if (!user) return;
        if (!message?.id) return;

        const paramLocation = `${location}_${room}`;

        mutate({
            id: message.id,
            jwt: jwt || '',
            location: paramLocation
        });
    }

    return (
        <Grid container direction="row"  sx={{minHeight: '100vh'}}>

            {/*INFO PANEL*/}

            <Grid item xs="auto" >
                <Box className={'jitsi-panel'}>
                    <Box ml={2} mr={1} className={'jitsi-panel__header'}>
                        <Box width={92}>
                            <Logo logoWithoutText={true}/>
                        </Box>
                        <CourtLogo width={180} location={location}/>
                    </Box>


                    {  /*info items*/  }


                    <Box className={'jitsi-panel__items'}>
                        {/*moderator controls*/}


                        {user && <Box className={'jitsi-panel__items_item jitsi-panel__items_item--moderator'}>
                            <Box sx={{width: '100%'}} >
                                <ModeratorPredefinedTextEditor onChange={handleChange}/>
                            </Box>
                            <Box sx={{width: '100%'}} >
                                <ModeratorFreeTextEditor jwt={jwt} forceText={forcedText} onSend={handleChange}/>
                            </Box>

                        </Box>}

                        {!isMessageError && messages && messages.map( o => (
                                <MessageItem key={'msg' + o.id}
                                             message={o}
                                             editable={!!user}
                                             onDelete={handleDelete} />
                            ))
                        }


                        {statuses.map((i, idx) => (
                                <MessageItem key={'sysmsg-' + i}
                                             message={{message: i, id: idx}}
                                             editable={false} />
                            ))
                        }
                    </Box>
                    <Box className={'jitsi-panel__faqs'}>
                        <img alt="faqs" style={{marginRight:`10px`}} src="/images/question-bold.svg"></img>
                        <Link className="text" underline="none" href={`/${location}/faqs`}>Hilfe</Link>
                    </Box>
                    <Box className={'jitsi-panel__bottom'}>
                        <BottomPanel width={100}/>
                    </Box>

                </Box>
            </Grid>


            {/*IFRAME*/}

            <Grid item xs  sx={{backgroundColor: 'white', width: '100%'}} >

                {isLoading && <h1 style={{position: 'absolute'}} >Loading</h1>}

                {isError && <h1 style={{position: 'absolute'}}>Error loading Jitsi Web application.</h1>}
 
                {end && <FarewellMessage location_id={location} room={room}/>}

                {!end &&  <div className={'jitsi'} ref={setNode}></div>}

            </Grid>
        </Grid>
    );


}

export default NewJitsi;