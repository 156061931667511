import { Box, CardMedia, Fade, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useData } from "../util/hooks";


export type LogoProps = {
    location ?: string;
    logoWithoutText ?: boolean
}

const FADE_MILLIS : number = 400;
const DEFAULT_LOGO : string = 'BMJ_Logo_Videoportal_rgb 1.png';

const Logo = ({location, logoWithoutText = false}: LogoProps) => {
    const loadedImg = useRef<string>('');
    const {data, isLoading} = useData();
    const [imgLoadComplete, setImgLoadComplete] = useState<boolean>(false);
    const [image, setImage] = useState<string>(DEFAULT_LOGO)
    const [changing, setChanging] = useState<boolean>(false);

    const loc = useMemo(() => {
        if (!data) return null;
        if (!location) return null;
        return data.find(o => o.location_id.toLowerCase() === location.toLowerCase());
    }, [location, data]);

    useEffect(() => {
        const img = loc?.img || DEFAULT_LOGO;

        const t = setTimeout(() => {
            setChanging(false);
            setImage(img);
            if (loadedImg.current !== img) setImgLoadComplete(false);
        }, FADE_MILLIS);

        setChanging(true);
        return () => clearTimeout(t);
    }, [loc]);

    if (isLoading) return <Box className="logo" />

    return (
        <Fade in={imgLoadComplete && !changing} timeout={FADE_MILLIS} appear>
            {
                logoWithoutText ? <CardMedia
                    component='img'
                    sx={{height: 95, width: 'auto', objectFit: 'contain'}}
                    image={`/images/bmj_logo_without_text.svg`}
                    onLoad={() => {
                        loadedImg.current = image;
                        setImgLoadComplete(true);
                    }}
                    alt={'Logo'}
                /> : <CardMedia
                        component='img'
                        sx={{height: 95, width: 'auto', objectFit: 'contain'}}
                        image={`/images/BMJ_Logo_Videoportal_rgb 1.png`}
                        onLoad={() => {
                            loadedImg.current = image;
                            setImgLoadComplete(true);
                        }}
                        alt={'Logo'}
                    />
            }
        </Fade>
    );
}

export default Logo;