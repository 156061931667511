import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import { Box, Button, CardMedia, Fade, Link, Paper, Stack, Typography } from "@mui/material";
import { useData } from "../util/hooks";
import Logo from "../components/Logo";
import AuthButton from "../components/AuthButton";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import BottomPanel from "../components/BottomPanel";
import config from "../config/config";
import FaqsButton from "../components/FaqsButton";
import CourtLogo from "../components/CourtLogo";
import {TData} from "../util/types";


const FADE_MILLIS : number = 400;

const NewLanding = () => {
    const navigate : NavigateFunction = useNavigate();

    const { location, room } = useParams();

    const {data} = useData();

    const [imgLoadComplete, setImgLoadComplete] = useState<boolean>(false);
    const [changing, setChanging] = useState<boolean>(false);
    const [image, setImage] = useState<string>('iStock-1062933252_bearb 1.png');
    const loadedImg = useRef<string>('');
   
    const loc = useMemo(() => {
        if (!data) return null;
        if (!location) return null;
        return data.find(o => o.location_id.toLowerCase() === location.toLowerCase());
    }, [location, data]);

    const convertAdditionalText = (loc: TData, room?:string) => {
        const parts = loc.additional_text.split('\n');
        const replaced = parts.map(o => {
            o = o.replace('{locname}', loc.name);
            o = o.replace('{room}', room || "");
            return o;
        });
        const elements:ReactNode[] = [];
        replaced.forEach((o,i) => {
            if (i>0) elements.push(<br/>);
            elements.push(o);
        })
        return elements;
    }

    useEffect(() => {
        const bg : string = loc?.bg || 'iStock-1062933252_bearb 1.png';

        const t = setTimeout(() => {
            setChanging(false);
            setImage(bg);
            if (loadedImg.current !== bg) setImgLoadComplete(false);
        }, FADE_MILLIS);

        setChanging(true);
        return () => clearTimeout(t);
    }, [loc]);

    let templateWelcome : React.ReactNode = (
        <>
            <Typography variant="h1">Willkommen im Digitalen Gerichtssaal des Videoportals der Justiz!</Typography>
            <Typography variant="h2" pt={2}>Sie nehmen an dieser Verhandlung teil: <br/>
                {location}, Aktenzeichen {room}</Typography>
        </>
    );

    if (loc) templateWelcome = (
        <>
            <Typography variant="h1">{loc.headline}</Typography>
            <Typography pt={2} variant="h2">{convertAdditionalText(loc, room)}</Typography>
        </>
    );

    const joinlicked = () : void => {
        console.log("join clicked");
        navigate(`/jitsi/${location}/${room}`);
    }

    return (
        <>
            <Box className={'nav'}>
                <Logo/>
                <CourtLogo location={location}/>
                <Stack direction="row">
                    <FaqsButton location={location}/>
                    <AuthButton />
                </Stack>
            </Box>
            <Box className={'bgImage container'} >
                <Box style={{ height: '100%', width: '100%' }}>

                    <Fade in={imgLoadComplete && !changing} timeout={FADE_MILLIS} appear>
                        <CardMedia
                            component='img'
                            sx={{ height: '100%', width: '100%' }}
                            image={`/images/locations/bg/${image}`}
                            onLoad={() => {
                                loadedImg.current = image;
                                setImgLoadComplete(true);
                            }}
                            alt={'bg'}
                        />
                    </Fade>

                    <BottomPanel />

                </Box>



                <Paper className={'infoItems'} elevation={5} square style={{opacity: '95%'}}>
                    <Box className={'infoItems__welcome'}>
                        {templateWelcome}
                    </Box>

                    <Box className={'infoItems__buttons-panel'}>
                        <Box className={'item'}>Haben Sie überprüft, ob Ihre Kamera und Ihr Mikrofon angeschlossen und aktiv sind?</Box>

                        <Box className={'buttons-wrapper'}>
                            <Button className={'not-ready'}
                                    variant="outlined"
                                    href={`${config.videoTestUrl}/?location=${location}&room=${room}`}
                                    fullWidth
                            >Jetzt überprüfen</Button>

                            <Button className={'ready'}
                                    variant="outlined"
                                    onClick={joinlicked}
                                    fullWidth
                            >
                                Ja, ich bin bereit
                            </Button>
                        </Box>
                    </Box>

                    <Box className={'infoItems__tip-panel'}>
                        <img alt="info" className={'icon'} src={'/images/faqs-button.svg'}/>
                        <Box className={'info'}><Link underline="none" href={`/${location}/faqs`}>Hier</Link> finden Sie die Antworten auf die häufigsten Fragen zum Digitalen Gerichtssaal oder zur Technik.</Box>
                    </Box>

                </Paper>

            </Box>


        </>
    );
}

export default NewLanding;