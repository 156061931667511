import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";

type BottomPanelType = {
    width ?: number,
}

const BottomPanel = ({ width } : BottomPanelType) => {
    const [opened, setOpened] = useState<boolean>(false);
    
    return (
        <Box 
            className={'bottom'} 
            sx={{
                height : opened ? width ? `calc(100vh - ${width}px)` : `calc(100vh - 120px)` : null,
                transform : !opened ? `translate3d(0, calc(100% - 59px), 0)` : null,
            }}
        >
            <Button  className={'impressumbutton'} onClick={() => setOpened(!opened)}>
                <img className={'upArrow'} alt={'up'} src={'/images/upArrow.svg'}
                     style={{
                         transform: opened ? 'rotate(-180deg)' : ''
                     }}/>
                <Box className={'impressum'}>IMPRESSUM</Box>
            </Button>
            <Box className={'impressumContentContainer'} >
                <Box className={'col1'}>
                    <Typography variant="h1" pt={2} pb={1.5}>Impressum</Typography>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Anbieter </Typography>
                        Bundesministerium der Justiz (BMJ) <br/>
                        Mohrenstraße 37<br/>
                        10117 Berlin<br/>
                        Telefon: 030/ 18 580 0<br/>
                        Fax: 030/ 18 580 - 95 25<br/>
                        E-Mail: email@bmj.bund.de<br/>
                        www.bmj.de
                    </Box>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Gesetzlicher Vertreter</Typography>
                        Das Ministerium wird durch den Bundesminister der Justiz vertreten.

                    </Box>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Verantwortlich</Typography>
                        Vera Verantwortlich
                    </Box>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Design und Technische Umsetzung</Typography>
                        Nordeck IT + Consulting, Hamburg
                    </Box>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Hosting und Betrieb</Typography>
                        Hostinganbieter
                    </Box>
                    <Box>
                        <Typography variant="h2" pb={1.5}>Bildnachweis</Typography>
                        Das Logo ist Eigentum der oben genannten Behörde. Das Startbild ist ein Stockphoto von iStockphoto.com.

                    </Box>
                </Box>
                <Box className={'col2'}>
                    <Typography variant="h1" pt={2} pb={1.5}>Rechtliche Hinweise (DSGVO ?)</Typography>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </Box>
            </Box>
        </Box>
    );
}

export default BottomPanel;