import { TMessageDTO } from "../util/types";
import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";


export type MessageItemProps = {
    message: TMessageDTO,
    editable: boolean,
    onDelete?: (message: TMessageDTO) => void
}


const MessageItem = ({message, editable, onDelete}: MessageItemProps) => {
    const [flash, setFlash] = useState<boolean>(true);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    useEffect(() => {
        setInterval(() => setFlash(false), 500);
    }, []);


    const handleDelete = () : void => {
        if (onDelete) onDelete(message);
    }

    return (
        <Box key={message.id}
             className={ 'jitsi-panel__items_item ' + (flash ? 'jitsi-panel__items_item--highlight' : '')}
             onMouseOver={() => setShowDelete(true)}
             onMouseOut={() => setShowDelete(false)}
        >

            <Box className={'content'}>
                {message.message
                    .split('\n')
                    .map((text, index) => ( <span key={`${message.id}-${index}`}> {index > 0 && <br />} {text} </span> ))}
            </Box>


            {message.timestamp && <Box className={'timestamp'}>
                {new Date(message.timestamp).toLocaleString('de-DE', {
                    hour12: false,
                    dateStyle: undefined,
                    timeStyle: 'short',
                    timeZone: 'Europe/Berlin'
                })}
            </Box>
            }

            {editable && showDelete && (
                <IconButton className={'delete-button'}
                            color="primary"
                            onClick={handleDelete}>
                    <img  alt={'X'} src={'/images/close.svg'} />
                </IconButton>
            )}

        </Box>
    );
}

export default MessageItem;