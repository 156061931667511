import { Box, IconButton, Tooltip } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TFaqsButton } from "../util/types";


const FaqsButton = ({ disabled, location } : TFaqsButton) => {
    const navigator : NavigateFunction = useNavigate();

    const clickHandler = () : void => {
        navigator(`/${location}/faqs`)
    }

    return (
        <Tooltip title="FAQs">
            <Box>
                <IconButton className="faqsBtn" disabled={disabled} onClick={clickHandler}>
                    <img alt="faqs" src="/images/faqs-button.svg"/>
                </IconButton>
            </Box>
        </Tooltip>
    );
}

export default FaqsButton;