import {Box, CardMedia, Fade} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useData} from "../util/hooks";


export type LogoProps = {
    location?: string,
    width ?: number
}

const FADE_MILLIS = 400;
const DEFAULT_LOGO = 'default.png';

const CourtLogo = ({location, width}: LogoProps) => {
    const loadedImg = useRef('');
    const {data, isLoading} = useData();
    const [imgLoadComplete, setImgLoadComplete] = useState(false);
    const [image, setImage] = useState(DEFAULT_LOGO)
    const [changing, setChanging] = useState(false);

    const loc = useMemo(() => {
        if (!data) return null;
        if (!location) return null;
        return data.find(o => o.location_id.toLowerCase() === location.toLowerCase());
    }, [location, data]);

    useEffect(() => {
        const img = loc?.img || DEFAULT_LOGO;

        const t = setTimeout(() => {
            setChanging(false);
            setImage(img);
            if (loadedImg.current !== img) setImgLoadComplete(false);
        }, FADE_MILLIS);

        setChanging(true);
        return () => clearTimeout(t);
    }, [loc]);

    if (isLoading) return <Box className="logo" />
    console.log(image)
    return (
        <Fade in={imgLoadComplete && !changing} timeout={FADE_MILLIS} appear>
            <CardMedia
                component='img'
                sx={{height: 95, width: width || 'auto', objectFit: 'contain'}}
                image={`/images/locations/logo/${image}`}
                onLoad={() => {
                    loadedImg.current = image;
                    setImgLoadComplete(true);
                }}
                alt={'Logo'}
            />
        </Fade>
    );
}

export default CourtLogo;