import {TGlobalContext} from "./types";
import {createContext, useContext} from "react";

const GlobalState = createContext<TGlobalContext>({
    state: {},
    updateState: () => {}
});

export function useCtx() {
    return useContext<TGlobalContext>(GlobalState);
}

export function useCtxState() {
    const {state} = useContext<TGlobalContext>(GlobalState);
    return state;
}

export default GlobalState;

