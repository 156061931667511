import config from '../config/config'

export async function getUser(code: string = '', path: string = '') {
    const url = `${config.backendURL}/userInfo`;

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include"
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        console.log("not authenticated", response.status);
        return null;
    }

    return response.json();
}

export async function getJwt():Promise<string> {
    const url = `${config.backendURL}/jitsiJwt`;

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include"
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        throw new Error('bad');
    }

    return response.text();
}

export function getLogoutURL(path: string):string {
    return `${config.backendURL}/doLogout`;
}

export function getLoginURL(path: string):string {
    return `${config.backendURL}/login`
}