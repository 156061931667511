import React, {useState} from "react";
import { Box, Button, CardMedia, FormControl, InputLabel, Link, OutlinedInput, Paper, Stack, Typography } from "@mui/material";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import LocationSelect from "../components/LocationSelect";
import {useNavigate, useParams} from "react-router-dom";
import AuthButton from "../components/AuthButton";
import Logo from "../components/Logo";
import BottomPanel from "../components/BottomPanel";
import config from "../config/config";
import FaqsButton from "../components/FaqsButton";


const SamplePage = () => {
    const [localLocation, setLocalLocation] = useState<string>('');
    const [localRoom, setLocalRoom] = useState<string>('');

    const navigate = useNavigate();
    const [errorRoom, setErrorRoom] = useState<string>('');
    const [errorLocation, setErrorLocation] = useState<string>('');

    const roomNameRegexp : RegExp = /^[a-zA-Z0-9äöüÄÖÜß()\-\s]{5,50}$/;

    /// auth
    const { location } = useParams();

    const readyClick = () => {
        console.log("join clicked");

        var error = false;

        if (!localLocation) {
            error = true;
            setErrorLocation('Required')
        }

        if (!localRoom) {
            error = true;
            setErrorRoom('Required');
        }

        if (error) return;

        setErrorRoom('');
        setErrorLocation('');

        navigate(`/${localLocation}/${localRoom}`);
    }

    return (
        <>
            <Box className={'nav'}>
                <Logo/>
                <Stack direction="row">
                    <FaqsButton location={location}/>
                    <AuthButton />
                </Stack>
            </Box>
            <Box className={'bgImage container'} >
                <CardMedia
                    sx={{ height: '100%', width: '100%' }}
                    image="/images/iStock-1062933252_bearb 1.png">
                    <BottomPanel />
                </CardMedia>
                <Paper className={'infoItems'} elevation={5} square>
                    <Box className={'infoItems__welcome'}>
                        <Typography variant="h1">Willkommen im Digitalen Gerichtssaal des Videoportals der Justiz!</Typography>
                        <Typography variant="h2" pt={2}>Um der Sitzung beizutreten, wählen Sie aus der Auswahlliste Ihren Gerichtssitz aus und geben Sie das Aktenzeichen ein. </Typography>
                    </Box>
                    <Box className={'infoItems__selectors-grid'}>
                        <LocationSelect
                            error={errorLocation}
                            onChange={(v) => setLocalLocation(v?.location_id || '')}
                        />
                        <Box className={'item'}>Gerichtssitz ist der Standort des zuständigen Gerichts. Diese Angabe finden Sie im Vorladungsschreiben.</Box>
                        <FormControl error={!!errorRoom} fullWidth>
                            <InputLabel htmlFor="LocationSelectRoom-roomid">Aktenzeichen</InputLabel>
                            <OutlinedInput
                                id="LocationSelectRoom-roomid"
                                aria-describedby="component-error-text"
                                inputProps={{maxLength: 50}}
                                label={'Aktenzeichen'}
                                readOnly={false}
                                autoComplete="off"
                                placeholder={'Hier Aktenzeichen eintragen'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value.trim();
                                    let err = '';
                                    if (value && !roomNameRegexp.test(value)) {
                                        err = 'Contains invalid characters.';
                                    }
                                    if (value && value.length < 5) {
                                        err = 'Too short.';
                                    }
                                    setErrorRoom(err);
                                    setLocalRoom(err ? '' : value);
                                }}
                            />
                        </FormControl>
                        <Box className={'item'}>Das Aktenzeichen finden Sie in der Vorladung. Es besteht aus einer 8-stelligen Buchstaben-Ziffern-Kombination.</Box>
                    </Box>
                    <Box className={'infoItems__buttons-panel'}>
                        <Box className={'item'}>Haben Sie überprüft, ob Ihre Kamera und Ihr Mikrofon angeschlossen und aktiv sind?</Box>
                        <Box className={'buttons-wrapper'}>
                            <Button 
                                className={'not-ready'}
                                variant="outlined"
                                href={config.videoTestUrl}
                                fullWidth
                            >Jetzt überprüfen</Button>
                            <Button 
                                className={'ready'}
                                variant="outlined"
                                onClick={readyClick}
                                fullWidth 
                            >Ja, ich bin bereit</Button>
                        </Box>
                    </Box>
                    <Box className={'infoItems__tip-panel'}>
                        <img alt="info" className={'icon'} src={'/images/faqs-button.svg'}/>
                        <Box className={'info'}><Link underline="none" href={`/faqs`}>Hier</Link> finden Sie die Antworten auf die häufigsten Fragen zum Digitalen Gerichtssaal oder zur Technik.</Box>
                    </Box>
                </Paper>
            </Box>
        </>
    );
}

export default SamplePage;