import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getLoginURL, getLogoutURL } from "../util/oidc";
import { useLocation } from "react-router-dom";
import {useUserFetch} from "../util/hooks";


export type AuthButtonProps = {
    onLogin?: () => void | null,
    onLogout?: () => void | null
}

const AuthButton = ({onLogout, onLogin}: AuthButtonProps) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const {pathname} = useLocation();

    const {user} = useUserFetch();
    const authenticated = !!user;

    // fix for the firefox BFCache
    useEffect(() => {
        const reset = () => setDisabled(false);
        window.addEventListener('pageshow', reset);
        return () => window.removeEventListener('pageshow', reset);
    }, []);

    function login() : void {
        setDisabled(true);
        if (onLogin) onLogin();
    }

    function logout() : void {
        setDisabled(true);
        if (onLogout) onLogout();
    }

    if (authenticated) {
        return (
            <form key={'logout'} action={getLogoutURL(pathname)} onSubmit={logout}>
                {disabled
                    ? <CircularProgress/>
                    : (
                        <Tooltip title="Logout">
                            <IconButton type={'submit'}>
                                <img src={'/images/exit.svg'} alt={'exit'}/>
                            </IconButton>
                        </Tooltip>
                    )
                }
            </form>
        );
    }

    return (
        <form key={'login'} action={getLoginURL(pathname)} onSubmit={login}>
            {disabled
                ? <CircularProgress/>
                : (
                    <Tooltip title="Login">
                        <IconButton type={'submit'}>
                            <img src={'/images/enter.svg'} alt={'enter'}/>
                        </IconButton>
                    </Tooltip>
                )
            }
        </form>
    );
}

export default AuthButton;