import MinimalLayout from '../layout/MinimalLayout';
import SamplePage from "../views/SamplePage";
import { Navigate, RouteObject } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import NewLanding from "../views/NewLanding";
import NewJitsi from "../views/NewJitsi";
import FaqsView from '../views/Faqs';

const MainRoutes: RouteObject = {
    path: '/',
    element: <ProtectedRoute />,
    children: [
        {
            path: '/',
            element: <MinimalLayout /> ,
            children: [
                { path: '/faqs', element: <FaqsView/>},
                { path: '/:location/faqs', element: <FaqsView/>},
                { path: '/sample', element: <SamplePage /> },
                { path: '/undefined/undefined', element: <SamplePage /> },
                { path: '/sample/:location/:room', element: <NewLanding /> },
                { path: '/:location', element: <NewLanding /> },
                { path: '/:location/:room', element: <NewLanding />  },
                { path: '/:nothing/:location/:room', element: <NewLanding /> },
                { path: '/jitsi/:location/:room', element: <NewJitsi /> },
            ]
        },

        { path: '*',  element:  <Navigate to='/' replace /> }
    ]
};

export default MainRoutes;