import {useQuery, useQueryClient} from "react-query";
import {getUser} from "./oidc";
import {useCallback, useEffect, useLayoutEffect, useRef} from "react";
import {JitsiOpts, TData, TFaqs, TMessage, TMessageDTO, TUser, TUserInfoDTO} from "./types";
import config from "../config/config";

export function useData() {
    const queryFn = async () => {
        const response = await fetch('/config/data_array.json');
        if (!response.ok) {
            throw new Error("can't fetch data_array.json");
        }
        const json:TData[] = await response.json();
        return json.sort((a, b) => a.name.localeCompare(b.name));
    }

    return useQuery<TData[]>(['data'],
        queryFn, {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            staleTime: Infinity,
            cacheTime: Infinity
        });
}

export function useFaqs(location : string | undefined) {
    const { data } = useData();

    const dataLocation = data?.find(element => element.location_id === location);
    const path = dataLocation?.faqs ? dataLocation.faqs : `faqs.json`;

    const queryFn = async () => {
        const response = await fetch(`/config/faqs/${path}`);
        if (!response.ok) {
            throw new Error(`can't fetch ${path}.json`);
        }
        const json:TFaqs[] = await response.json();
        return json;
    }

    return useQuery<TFaqs[]>([path],
        queryFn, {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            staleTime: Infinity,
            cacheTime: Infinity
        });
}

export function usePredefinedMessages() {
    const queryFn = async () => {
        const response = await fetch('/config/messages.json');
        if (!response.ok) {
            throw new Error("can't fetch messages.json");
        }
        return await response.json();
    }

    return useQuery<TMessage[]>(['predefinedMessages'],
        queryFn, {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            staleTime: Infinity,
            cacheTime: Infinity
        });
}

export function useJitsiLoader(jitsiFQDN: string, {enabled, location, room, jwt, node, preferredName, email, role, onEnd}: JitsiOpts) {
    const isMounted = useRef<boolean>(false);
    const onEndStable = useStableCallback(onEnd);

    // react-query will make sure that the script is loaded only once

    const loader = async (url: string) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.async = true;
            script.src = url;
            script.type = "text/javascript";
            script.onload = () => {
                console.log('SCRIPT ADDED');
                resolve('loaded');
            };
            script.onerror = (e) => {
                reject(e);
            }

            document.body.appendChild(script);
        });
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false
        }
    }, []);


    const { isLoading, isError, error, isSuccess: scriptLoaded  } = useQuery<any, Error>(
        ['jisti-js'],
        () => loader(`https://${jitsiFQDN}/external_api.js`),
        {
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    useEffect(() => {
        if (!scriptLoaded) return;
        if (!node) return;
        if (!isMounted.current) return;
        if (!enabled) return;

        const domain = `${jitsiFQDN}`;
        const options = {
            roomName: `${location} ${room}`,
            width: "100%",
            height: "100%",
            parentNode: node,
            userInfo: {
                // displayName: 'Guest',
                // email: ''
            } as {displayName?: string, email?:string},
            jwt: '',
        };


        if (jwt) {
            options.jwt = jwt;
            options.userInfo.displayName = preferredName;
            options.userInfo.email = email;
        }

        const api = new (window as any).JitsiMeetExternalAPI(domain, options);
        console.log('JITSI SETUP');

        // when Jitsi Meet is ready to be closed (i.e., hangup operations are completed).
        api.addListener("readyToClose", onEndStable);

        // when the local user has left the video conference.
        api.addListener("videoConferenceLeft", onEndStable);

        return () => {
            console.log('JITSI API DISPOSED!')
            api.dispose();
        }
    }, [enabled, scriptLoaded, location, room, jwt, preferredName, email, role, node, jitsiFQDN, onEndStable]);

    return { isLoading, isError, error };
}

export function useUserFetch(authcode:string = '', path:string = '', onLogin?:(user: any)=>void, onError?:(e:Error)=>void) {
    const queryClient = useQueryClient();
    const minutes = (m:number)  =>  m * (60 * 1000);

    const { isLoading, isError, data, error, isSuccess, remove } = useQuery<TUserInfoDTO|null, Error>(
        ['user'],
        () => getUser(),
        {
            enabled: true,
            staleTime: minutes(10),
            cacheTime: Infinity,
            refetchOnMount: false,
            retryOnMount: false,
            onSuccess: (user) => {
                _onLogin(user)
                if (onLogin) onLogin(user);
            },
            onError: (e) => {
                _onError(e);
                if (onError) onError(e);
            }
        }
    );

    function _onLogin(user: TUserInfoDTO | null) {
        console.log(`Logged in as user ${user?.preferredUsername}`)
    }

    function _onError(e: any) {
        console.log('use query error', e);
    }

    const invalidateQueries = () => {
        return queryClient.invalidateQueries(['user', authcode]);
    }

    return {
        isLoading,
        isError,
        user: data,
        error,
        isSuccess,
        invalidateQueries,
        remove
    }
}

export function useMessageQuery(location: string): {
    isLoading: boolean,
    isError: boolean,
    messages: TMessageDTO[],
    isSuccess: boolean,
} {

    const {isLoading, isError, data, isSuccess}  = useQuery(
        ['messages', location],
        () => getMessagesAsync(location),
        {
            enabled: true,
            refetchOnMount: true,
            retryOnMount: true,
            staleTime: 1*1000,
            refetchInterval: 10*1000,
        }
    );

    const getMessagesAsync = async (location: string) => {
        const url = `${config.backendURL}/api/messages/${encodeURIComponent(location)}`;
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('failed to fetch messages');
        }
        return response.json();
    }

    return {
        isLoading,
        isError,
        messages: data,
        isSuccess,
    }
}

type TFn = (...args: any[]) => any;

// provided fn may change on every render, but the returned function is stable
export function useStableCallback(fn: TFn): TFn {
    const ref: any = useRef(fn);

    useLayoutEffect(() => {
        ref.current = fn;
    });

    return useCallback<TFn>(
        (...args: any[]) => ref.current.apply(void 0, args),
        []
    );
}