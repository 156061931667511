import { Alert, Autocomplete, Box, LinearProgress, TextField, Tooltip } from "@mui/material";
import React, {useMemo, useState} from "react";
import { useData } from "../util/hooks";
import { TData } from "../util/types";

export type LocationValue = {
    label: string,
    value: TData
}

export type LocationSelectProps = {
    locationId?: string | null,
    error?: string | null,
    onChange?: (newValue: TData | null) => void
}

const LocationSelect = ({locationId, error, onChange}: LocationSelectProps) => {
    const {isLoading, data, isError} = useData();
    const [tooltip, setTooltip] = useState<string>('');

    const opts = useMemo(() => {
        if (!data) return [];
        return data.map((o) => {
            return {
                label: `${o.name} (${o.location})`,
                value: o
            }
        })
    }, [data]);

    const findLocationLabel = (locationId: string = '') : string => {
        if (!data) return '';
        const loc = data.find(o => o.location_id.toLowerCase() === locationId.toLowerCase());
        if (loc) return `${loc.name} ${loc.location}`;
        return '';
    }

    const handleValueChange = (event: React.SyntheticEvent, newValue: LocationValue | null) : void => {
        const {label, value} = newValue || {};
        setTooltip(label || '');
        if (onChange) onChange(value || null);
    }

    if (isError) {
        return (
            <Box sx={{width: '100%'}} minWidth={'300px'}>
                <Alert severity="error">Problem with the database.</Alert>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box sx={{width: '100%'}} minWidth={'300px'}>
                <LinearProgress/>
            </Box>
        );
    }

    if (locationId) {
        return (
            <Box sx={{width: '100%'}} minWidth={'300px'}>
                <TextField multiline aria-readonly
                           label="Location"
                           value={findLocationLabel(locationId)}
                           fullWidth
                           InputProps={{
                               readOnly: true,
                           }}
                />
            </Box>
        );
    }

    return (
        <Box minWidth={'300px'}>
            <Tooltip title={tooltip} arrow placement={'top'}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    loading={isLoading}
                    options={opts}
                    onChange={handleValueChange}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            error={!!error}
                            label="Location"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Tooltip>
        </Box>
    );
}


export default LocationSelect;