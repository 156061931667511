import ReactMarkDown from "react-markdown";
import { useState } from "react";
import { useFaqs } from "../util/hooks";
import { useParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"

const FaqAccordion = () => {
    const [expanded, setExpanded] = useState<number | false>(false);
    const { location } = useParams();
    const { data, isLoading } = useFaqs(location);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            {isLoading ? null : data?.map((element, index) => {
                return (
                    <Accordion 
                        className="accordion" 
                        key={index}
                        onChange={handleChange(index)}
                        expanded={expanded === index}
                        sx={{
                            "&.Mui-expanded": {
                                backgroundColor: "#F2F3F4",
                                margin : "0px"
                            }
                        }}
                    >
                        <AccordionSummary expandIcon={<img alt="arrow" src="/images/accordion-arrow.svg"/>}>
                            <ReactMarkDown className="accordion-sammary">{element.title}</ReactMarkDown>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ReactMarkDown components={{
                                a(props) {
                                    const { ...rest } = props;

                                    return <a target="_blank" {...rest}/>
                                }
                            }} className="accordion-details">{element.description}</ReactMarkDown>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    )
}

export default FaqAccordion;