const getCustomConfig = () => window.customConfig || {};

const config = {
    "nodeEnv": process.env.NODE_ENV,
    "version": process.env.REACT_APP_VERSION,
    "jitsiFQDN": getCustomConfig().REACT_APP_JITSI_FQDN || process.env.REACT_APP_JITSI_FQDN,
    "oidcFQDN": getCustomConfig().REACT_APP_VIDEOTEST_URL || process.env.REACT_APP_OIDC_FQDN,
    "backendURL": getCustomConfig().REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL,
    "videoTestUrl": getCustomConfig().REACT_APP_VIDEOTEST_URL || process.env.REACT_APP_VIDEOTEST_URL,
    "debugEnableStatusMessages": process.env.REACT_APP_DEBUG_ENABLE_STATUS_MESSAGES,
};

export default config;