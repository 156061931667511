import { Typography, Stack, Divider, Button, Grid, Box } from "@mui/material"
import Logo from "../components/Logo";
import FaqsButton from "../components/FaqsButton";
import AuthButton from "../components/AuthButton";
import BottomPanel from "../components/BottomPanel";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import FaqAccordion from "../components/FaqAccordion";


const Faqs = () => {
    const navigator : NavigateFunction = useNavigate();
    const { location } = useParams();
    
    const clickButtonHandler = () : void => {
        navigator(-1);
    };

    return (
        <>
            <Box className={'nav'}>
                <Logo/>
                <Stack direction="row">
                    <FaqsButton disabled={true}/>
                    <AuthButton />
                </Stack>
            </Box>
            <Box className="header-bottom"></Box>
            <Grid className="faqs">
                <Typography className="title">Häufig gestellte Fragen</Typography>
                <Box className="faqs-container">
                    <Stack sx={{width : `60%`}}>
                        <Typography className="sub-text">
                            Willkommen auf unserer FAQ-Seite, auf der wir die am häufigsten gestellten Fragen zu OpenConference 
                            beantworten.erne an uns wenden, um weitere Unterstützung zu erhalten.
                        </Typography>
                        <FaqAccordion/>
                    </Stack>
                </Box>
                <Box className="faqs-footer">
                    <Divider/>
                    <Button className="back-button" onClick={clickButtonHandler}>zurück zur vorherigen Seite</Button>
                </Box>
            </Grid>
            <BottomPanel/>
        </>
    );
}

export default Faqs;